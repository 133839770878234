import React from 'react';
import styles from './About.module.css';
import { Helmet } from 'react-helmet';
import YARINlAHAV from '../Content/YARINlAHAV.jpg'
function About() {
  return (
    <div className={styles.aboutContainer}>
    <Helmet>
      <title>About Us - Lahav Group | Real Estate Leaders in Israel</title>
      <meta name="description" content="Learn about Lahav Group, a leading real estate company based in Tel Aviv. We specialize in organizing purchasing groups for major real estate projects across Israel, offering clients unparalleled expertise and savings." />
    </Helmet>

      <h1 className={styles.h1}>About</h1>
      <h2 className={styles.h2}>הסיפור שלנו </h2>
      <div className={styles.producerSection}>
        <img src={YARINlAHAV} alt="Producer" className={styles.producerImage} />
        <h3 className={styles.producerName}>Yarin Lahav</h3>
      </div>
      <div className={styles.contentSection}>
        <h4>קבוצת להב מתמחה בעסקאות נדל"ן בסקטור הפרטי והעסקי.</h4>
        <p>קבוצת להב היא חברה מובילה בתחום הנדל"ן, המתמחה ביצירת פתרונות מתקדמים ומשתלמים עבור לקוחות פרטיים ועסקיים. החברה מתמקדת באיגוד קבוצות רכישה באזור המרכז במסגרת תוכנית "זכות לדירה", ומציעה ללקוחותיה הזדמנות ליהנות מחיסכון משמעותי של עד 30% ממחיר השוק. במקביל, החברה מארגנת קבוצות רוכשים בפרויקטים נבחרים ברחבי הארץ, המאפשרים ללקוחות לחסוך עד 10% ממחיר הדירות.

            מתוך הבנה למורכבות ולדינמיות של תחום הנדל"ן בישראל, קבוצת להב שמה דגש על ניהול מקצועי, אישי ופרטני של כל עסקה. החברה פיתחה קשרים אסטרטגיים עם ועדות התכנון השונות ומעסיקה את בעלי המקצוע המובילים בענף, במטרה להבטיח ללקוחותיה ליווי מקצועי, בדיקות עומק מעמיקות ושקט נפשי לאורך כל התהליך.

            שירות מותאם אישית
            קבוצת להב שמה את הלקוח במרכז, ומתחייבת להתאים לכל אחד פתרונות נדל"ן המותאמים לצרכיו ולשאיפותיו. אנו מקפידים ללוות את הלקוחות לאורך כל הדרך, משלב התכנון ועד השלמת העסקה, תוך מתן שירות אישי, מהיר ושקוף.

            יתרונות תחרותיים
            הניסיון הרב של קבוצת להב בתחום הנדל"ן והצוות המיומן שלה מאפשרים לנו לזהות הזדמנויות ייחודיות בשוק ולהשיג יתרונות משמעותיים עבור לקוחותינו – הן בחיסכון בעלויות והן במינוף פוטנציאל הנכס.

            השקעות נדל"ן ויוזמות ייחודיות
            מעבר לפעילותה בתחום קבוצות הרכישה והרוכשים, קבוצת להב משקיעה גם בנדל"ן באופן עצמאי. הקבוצה יוזמת ומנהלת פרויקטים ייחודיים הכוללים רכישת נכסים, ביצוע שיפוצים והשבחתם, מתוך מטרה למקסם את פוטנציאל הרווח של הנכסים ולהעלות את ערכם בשוק.

            תרומה לקהילה והתחדשות עירונית
            אנו גאים לקחת חלק בתהליכים של התחדשות עירונית, במטרה לחזק את המרקם הקהילתי, לשפר את איכות החיים של התושבים ולתרום לשימור והעלאת הערך של הסביבה העירונית בישראל.

            חזון לעתיד
            חזון קבוצת להב הוא להמשיך להוביל את שוק הנדל"ן בישראל באמצעות חדשנות, שקיפות ומקצועיות, ולהפוך לשותפה האמינה ביותר עבור כל לקוח בדרכו להשקעות נדל"ן חכמות ומשתלמות.

            ערכי ליבה וסיפור המייסדים
            החברה נוסדה מתוך אמונה שלכל אחד מגיעה הזדמנות להחזיק בנכס נדל"ן איכותי וליהנות משקט נפשי בתהליך הרכישה או ההשקעה. ערכי הליבה שלנו – מקצועיות, אמינות, שקיפות ושירות ללא פשרות – מנחים אותנו בכל צעד ובכל פרויקט שאנו מובילים.</p>
        
      </div>
    </div>
  );
}

export default About;
